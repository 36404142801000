export const environment = {
    production: false,
    APP_URL: 'https://stg.fashquik.com',
    IMAGE_DOMAIN: 'http://nextcloud.fashquik.com',
    WMS_API_URL: 'https://wms-api.stg.fashquik.com/api/core',
    WMS_API_APP_URL: 'https://wms-api.stg.fashquik.com/api/app',
    // WMS_API_URL: 'http://localhost:5000/api/core',
    // WMS_API_APP_URL: 'http://localhost:5000/api/app',
    OUMS_API_APP_URL: 'https://oums-api.stg.fashquik.com/api/app',
    // OUMS_API_APP_URL: 'http://localhost:9000/api/app',
    PMS_API_APP_URL: 'https://pms-api.stg.fashquik.com/api/app',
    GOOGLE_MAP_KEY: 'AIzaSyAto-YQGOiLk7pD0T8GTeTJJHBGaOrQHI8',
    // razorPe_key:'rzp_test_JOC0wRKpLH1cVW', 
    razorPe_key:'rzp_test_Yf9mL3HFKKgh3M' ,  
    PMS_UPLOADIMAGE_URL:'https://pms-api.stg.fashquik.com/api'

}