import { Action } from "@ngrx/store";
import { DeviceDetectorResult } from "device-detector-js";


export enum AppActionTypes {
    UpdateUser = '[App] Update User',
    UpdateUserToken = '[App] Update User Token',
    RemoveItemFromCart = '[App] Remove Item From Cart',
    ClearCart = '[App] Clear Cart',
    UpdateMeta = '[App] Update Meta',
    AddItemToCart = '[App] Add Item To Cart',
    UpdateLoaderVisibility = '[App] Update Loader Visibility',
    UpdateDeviceDetails = '[App] Update Device Details',
    UpdateDeviceId = '[App] Update DeviceId',
    UpdateAppConfig = '[App] Update App Config',
    UpdateEventConfig = '[App] Update Event Config',
    UpdateDeliveryLocation = '[App] Update Delievery Location',
    UpdateCart = '[App] Update cart',
    Logout = '[App] Logout',
    IncreaseItemQuantity = '[App] Increase Item Quantity',
    DecreaseItemQuantity = '[App] Decrease Item Quantity'
}

export class UpdateUser implements Action {
    readonly type = AppActionTypes.UpdateUser;

    constructor(public user: any){}
}

export class UpdateUserToken implements Action {
    readonly type = AppActionTypes.UpdateUserToken;

    constructor(public userToken: any){}
}

export class UpdateDeliveryLocation implements Action {
    readonly type = AppActionTypes.UpdateDeliveryLocation;

    constructor(public deliveryLocation: any){}
}

export class UpdateDeviceId implements Action {
    readonly type = AppActionTypes.UpdateDeviceId;

    constructor(public deviceId: any){}
}

export class AddItemToCart implements Action {
    readonly type = AppActionTypes.AddItemToCart;
  
    constructor(public item: any) {}
}
  
export class RemoveItemFromCart implements Action {
    readonly type = AppActionTypes.RemoveItemFromCart;
  
    constructor(public item: any) {}
}
  
export class ClearCart implements Action {
    readonly type = AppActionTypes.ClearCart;
  
    constructor() {}
}

export class IncreaseItemQuantity implements Action {
    readonly type = AppActionTypes.IncreaseItemQuantity;
  
    constructor(public item: any, public quantity: any) {}
}

export class DecreaseItemQuantity implements Action {
    readonly type = AppActionTypes.DecreaseItemQuantity;
  
    constructor(public item: any, public quantity: any) {}
}


export class UpdateMeta implements Action {
    readonly type = AppActionTypes.UpdateMeta;

    constructor(public meta: any){}
}

export class Logout implements Action {
    readonly type = AppActionTypes.Logout;

    constructor(){}
}

export class UpdateLoaderVisibility implements Action {
    readonly type = AppActionTypes.UpdateLoaderVisibility;

    constructor(public visibility: any){}
}

export class UpdateDeviceDetails implements Action {
    readonly type = AppActionTypes.UpdateDeviceDetails;

    constructor(public deviceDetails: DeviceDetectorResult){}
}

export class UpdateAppConfig implements Action {
    readonly type = AppActionTypes.UpdateAppConfig;

    constructor(public appConfig: any){}
}

export class UpdateEventConfig implements Action {
    readonly type = AppActionTypes.UpdateEventConfig;

    constructor(public eventConfig: any){}
}

export type AppActions = 
    | UpdateUser
    | UpdateUserToken
    | UpdateMeta
    | UpdateDeviceDetails
    | UpdateLoaderVisibility
    | UpdateAppConfig
    | UpdateEventConfig
    | UpdateDeliveryLocation
    | AddItemToCart
    | RemoveItemFromCart
    | UpdateDeviceId
    | ClearCart
    | IncreaseItemQuantity
    | DecreaseItemQuantity
    | Logout;