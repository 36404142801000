export const environment = {
    production: true,
    APP_URL: 'https://fashquik.com',
    IMAGE_DOMAIN: 'http://nextcloud.fashquik.com',
    // WMS_API_URL: 'https://wms-api.stg.fashquik.com/api/core',
    WMS_API_URL: 'http://localhost:5000/api/core',
    WMS_API_APP_URL: 'http://localhost:5000/api/app',
    OUMS_API_APP_URL: 'https://oums-api.stg.fashquik.com/api/app',
    PMS_API_APP_URL: 'https://pms-api.stg.fashquik.com/api/app',
    // WMS_API_APP_URL: 'https://wms-api.stg.fashquik.com/api/app',
    GOOGLE_MAP_KEY: 'AIzaSyAto-YQGOiLk7pD0T8GTeTJJHBGaOrQHI8'
}